import React from "react";
import { Redirect } from 'react-router-dom';
import {Button, Form} from 'semantic-ui-react';
import {PRIMARY_COLOR} from "../stylingConstants";
import {connect} from "react-redux";
import {joinedAsTeam, receivedFromTeamChannel, receivedFromHostChannel, startedHosting} from "../actions";
import SocketHandler from "../lib/SocketHandler";

class ReconnectToGameForm extends React.Component {

  state = {
    reconnectCode: this.props.latestReconnectCode,
    reconnectCodeErrorMessage: "",
    reconnectCodeCorrectLength: !!this.props.latestReconnectCode,
  }

  onChangeReconnectCode(event) {
    this.setState({
      reconnectCode: event.target.value,
      reconnectCodeErrorMessage: '',
      reconnectCodeCorrectLength: event.target.value.length === 6 || event.target.value.length === 10
    });
  }

  onReconnectClicked() {
    if (this.state.reconnectCode.length === 10) {
      this.reconnectHost();
    } else {
      this.reconnectTeam();
    }
  }

  reconnectTeam() {
    fetch("/games/reconnect_team", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json"
      },
      "body": JSON.stringify({'reconnect_code': this.state.reconnectCode})
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if( result === null) {
          this.setState({
              reconnectCodeErrorMessage: 'Could not find a team with that reconnect code, please double check it'
            }
          );
        } else {
          const outerThis = this;
          SocketHandler.subscribeToTeamChannel(result.team.id, {
            received(data) {
              console.log(data);
              outerThis.props.receivedFromTeamChannel(data);
            }});
          this.props.joinedAsTeam(result.team);
          this.props.receivedFromTeamChannel(result.game_state)
        }
      })
      .catch(err => {
        this.setState({
            joinCodeErrorMessage: 'Could not find a team with that reconnect code, please double check it.'
          }
        );
        console.log(err);
      });
  }

  reconnectHost() {
    fetch("/games/reconnect_host", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json"
      },
      "body": JSON.stringify({'reconnect_code': this.state.reconnectCode})
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if( result === null) {
          this.setState({
              reconnectCodeErrorMessage: 'Could not find a game with that reconnect code, please double check it'
            }
          );
        } else {
          const outerThis = this;
          SocketHandler.subscribeToHostChannel(result.game.id, {
            received(data) {
              console.log(data);
              outerThis.props.receivedFromHostChannel(data);
            }});
          if(result.game_state.length > 0) {
            this.props.startedHosting(result.game);
            // A bit of a hack to use the receivedFromTeamChannel method here
            // but it updates the game state to reasonable values
            this.props.receivedFromTeamChannel(result.game_state);
          } else {
            // We do not support reconnecting to a game collecting players or setting initial conditions
            this.setState({
                reconnectCodeErrorMessage: 'The game you were trying to reconnect to has not finished' +
                  ' initializing and cannot be reconnected to, please start from scratch with a new game.'
              }
            );
          }
        }
      })
      .catch(err => {
        this.setState({
            joinCodeErrorMessage: 'Could not find a team with that reconnect code, please double check it.'
          }
        );
        console.log(err);
      });
  }


  render() {
    if( this.props.joined ) {
      return null;
    }
    return (
      <>
        <h2>Reconnect to an existing game</h2>
        <p>
          To reconnect to an existing game that you have lost connection to,
          enter the reconnect code. This should be populated automatically if you were connected on this
          browser previously, you can also use a reconnect code from another computer.
        </p>
        <Form>
          <Form.Input
            required
            type="text"
            label="Reconnect code"
            value={this.state.reconnectCode}
            onChange={this.onChangeReconnectCode.bind(this)}
            {...(this.state.reconnectCodeErrorMessage ?
              {error: {content: this.state.reconnectCodeErrorMessage }} : {})}
          />
          <Button
            color={PRIMARY_COLOR}
            onClick={this.onReconnectClicked.bind(this)}
            disabled={!(this.state.reconnectCodeCorrectLength)}
          >
            Reconnect to game
          </Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    joined: !!state.onlineState.teamId,
    latestReconnectCode: state.latestReconnectCode,
  }
};

export default connect(mapStateToProps, {
  joinedAsTeam, receivedFromTeamChannel, receivedFromHostChannel, startedHosting
})(ReconnectToGameForm);