import React from 'react';
import { Popup, Button } from 'semantic-ui-react';

class ScreenShotInstructionsPopup extends React.Component {

  render() {
    return (
      <Popup wide='very'
        trigger={<Button content='Export to image' />}
        on='click'
      >
        <h4>To get an image of the graph, use the screenshot functionality on your computer</h4>
        <h5>Mac</h5>
        <p>Press Command+Shift+4 and then mark the area you want to take a
        screenshot of with the mouse pointer. By default the screenshots
        are saved to the desktop.</p>
        <h5>Windows 11</h5>
        <p>The built-in Snipping Tool lets you snap, save, annotate and share screenshots
          of all or a portion of your screen. The easiest way to call up the Snipping Tool
          is by using the keyboard shortcut "Windows + Shift + S".
          You can also open the tool from the task bar, in that case you will need to click
          the ”New” button to initiate screen capture. After you have captured your image you can
          copy it to the clipboard by pressing the copy icon (at the top right of the snipping tool).
          You can paste it in any application by going to that application and selecting
          Edit > Paste or pressing Ctrl + V.
        </p>
        <h5>Windows 10</h5>
        <p>Press “Windows + Shift + S”. Your screen will appear grayed out and
        your mouse cursor will change. Click and drag on your screen to select
        the part of your screen you want to capture. The screenshot will be
        copied to the clipboard. You can paste it in any application by
        selecting Edit > Paste or pressing Ctrl + V.</p>
        <h5>Earlier windows versions</h5>
        <p>You can take a screenshot by pressing the Print Screen button on your
        keyboard (usually labeled as “PrtScn,” “PrntScrn,” “Print Scr,” or
        something similar). On laptop keyboards, you may have to press the
        “Function” or “Fn” key to access the Print Screen feature. When you
        press the key, it will look like nothing happened, but the screenshot
        as been saved to your clipboard. You can paste it in any application by
        selecting Edit > Paste or pressing Ctrl + V.</p>
      </Popup>

    );
  }

}

export default ScreenShotInstructionsPopup;
