import React from 'react';
import { connect } from 'react-redux';
import {Link, Redirect, withRouter} from 'react-router-dom';
import { Dropdown, Menu, Image, Message, Popup, Icon } from 'semantic-ui-react';

import TeamsEditModal from './TeamsEditModal';
import { rollbackOneYear, dismissOfflineAvailableMessage, setTeamNames, quitCurrentGame } from '../actions';
import { PRIMARY_COLOR } from '../stylingConstants';
import logo from '../images/fishbanks_header.png'; // Tell Webpack this JS file uses this image

class Header extends React.Component {

  onShowPage() {
    return this.props.location.pathname === '/show';
  }

  renderRollbackMenuItem() {
    if( this.props.showRollbackOption ) {
      return (
        <Dropdown.Item
          onClick={ () =>  this.props.rollbackOneYear() }
        >
          Move back one year
        </Dropdown.Item>
      );
    }
  }

  renderEditTeamsMenuItem() {
    if( this.props.showEditTeamsOption && this.onShowPage() ) {
      return (
        <TeamsEditModal initialTeamNames={this.props.teamNames} setTeamNames={this.props.setTeamNames}/>
      );
    }
  }

  renderOfflineModeAvailableMenuItem() {
    if( this.props.showOfflineAvailableIcon ) {
      return (
        <Menu.Item>
          <Popup
            content='Fishbanks is now available offline in this browser.'
            trigger={<Icon name='download' />}
            position='top right'
          />
        </Menu.Item>
      );
    }
  }

  renderMenu() {
    if( this.onShowPage() ) {
      return (
          <Menu color={PRIMARY_COLOR} inverted>
            <Dropdown item text='Game'>
              <Dropdown.Menu>
                {this.renderRollbackMenuItem()}
                { this.props.showRollbackOption && <Dropdown.Divider /> }
                <Dropdown.Item style={{color: 'rgba(0,0,0,.87)'}}
                  onClick={ () =>  this.props.quitCurrentGame() }
                >
                  Quit game and go back to home page.
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* Wanted to have the menu item inside the dropdown but when I had
              it there I got issues with not beeing able to add spaces to team
              names since the dropdown 'stole' those events */}
            {this.renderEditTeamsMenuItem()}
            <Menu.Menu position='right'>
              {this.renderOfflineModeAvailableMenuItem()}
            </Menu.Menu>
          </Menu>
      );
    }
    return null;
  }

  renderOfflineAvailableMessage() {
    if( this.props.showOfflineAvailableMessage ) {
      return (
        <Message
          success
          icon
          onDismiss={() => this.props.dismissOfflineAvailableMessage()}
          >
          <Icon name="download" />
          <Message.Content>
            <Message.Header>All files downloaded</Message.Header>
            From now on Fishbanks can be used without an active internet connection in this browser.
          </Message.Content>
        </Message>
      );
    }
  }

  render() {
    return (
      <>
        <h1><Image id='header-image' src={logo} alt="Fishbanks"></Image></h1>
        <Message className="small-screen-error" error>
        <Message.Header>The browser window is not wide enough.</Message.Header>
        Currently the Fishbanks game requires that your window is at least 720 pixels wide in order for it to work properly.
        </Message>
        {this.renderMenu()}
        {this.renderOfflineAvailableMessage()}
      </>
    );
  }

}

const mapStateToProps = (state) => {
  const singleTeamMode = !!state.onlineState.teamId
    const showEditTeamsOption = (state.gameStates.length >= 1) && !singleTeamMode;
    let teamNames = [];
    if(showEditTeamsOption) {
      teamNames = state.gameStates[0].teams.map(team => team.name);
    }
    return {
      showRollbackOption: (state.gameStates.length > 1) && !singleTeamMode,
      showEditTeamsOption,
      showOfflineAvailableIcon: state.offlineModeAvailable,
      showOfflineAvailableMessage: state.offlineModeAvailableMessageStatus === 'VISIBLE',
      teamNames,
    };
}
export default withRouter(connect(mapStateToProps, {
  rollbackOneYear,
  dismissOfflineAvailableMessage,
  setTeamNames,
  quitCurrentGame,
})(Header));
