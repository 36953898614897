import { INITIAL_SHIP_SALVAGE_VALUE } from '../constants';
import { initialNumberOfShipsFromNumberOfTeams } from './setup';
import { randomWeather } from "./weather";
// Global arrays
// Note: Since the original program was written in BASIC, where arrays are 1-based
//       by default, the first item (at index 0) in these arrays is ignored unless
//       specifically noted.

// New Fish
var regeneration = [0,0,1,2,4,7,10,11,9.5,5.5,3,0];

// Ship Efficiency Multipliers
var productivity_1 = [0,0,5,10,15,20,25,25,25,25,25,25];
var productivity_2 = [0,0,3,6,9,12,15,15,15,15,15,15];

// X-axis values
var z = [0,0,0,0.1,0,0.2,0,0.3,0,0.4,0,0.5,0,0.6,0,0.7,0,0.8,0,0.9,0,1,0];

var weatherArray = []
var weatherLength = 21;
for(var weatherIndex = 0; weatherIndex < weatherLength; weatherIndex++) {
  weatherArray[weatherIndex] = randomWeather();
}
// Old code with fake random weather
// Weather - reproducible "random" sequence, repeats after 20 turns if necessary
// Note: This array was originally 0-based, so the 0-indexed element is used
// var weather = [1.00, 1.03, 0.87, 1.14, 1.05, 0.94, 0.90, 1.02, 1.08, 0.89, 1.08,
//               1.10, 1.01, 1.13, 1.06, 1.19, 1.16, 1.10, 1.01, 1.07, 1.11];

// This function was converted directly from the original
// Fishbanks code
function table(x, zarray) {
	var l, y, nn;

	// table function subroutine
	l = (zarray.length - 1)/2
	if (x <= zarray[1]) {y = zarray[2];}

	if (x > zarray[2*l-1]) {y = zarray[2*l];}

	for (nn = 3; nn <= 2*l-1; nn += 2) {
		if (x <= zarray[nn]) {
			// interpolate
			y = zarray[nn-1] + (zarray[nn+1]-zarray[nn-1])*(x-zarray[nn-2])/(zarray[nn]-zarray[nn-2])
			break;
		}
	}

	return y;
}

// Teams are not allowed to make their own decisions the initial year
export function initialTeamDecisions(gameState) {
  // First year decisions are set to one ship in coast area and rest in deep sea
  // No auction, purchases or sales of ships
  const numberOfTeams = gameState.teams.length;
  var teamDecisions = [];
  for( var teamIndex = 0; teamIndex < numberOfTeams; teamIndex++) {
    teamDecisions[teamIndex] = {
      auctionShips: 0,
      auctionDols: 0,
      shipPurch: 0,
      shipPurchDols: 0,
      shipSales: 0,
      shipSalesDols: 0,
      shipOrders: 0,
      shipsToDeep: gameState.teams[teamIndex].numberOfShips - 1,
      shipsToCoast: 1
    }
  }
  return teamDecisions;
}

// This function performs the calculations to advance the game through the next turn.
// It is called when the user is ready to accept the teams' decisions as entered into
// the decisions window. In the original code it was called calculate.
export function executeTurn(gameState, teamDecisions, weather=weatherArray) {

  const firstTurn = gameState.year === 0;

  const numberOfTeams = gameState.teams.length;
	// Local scratch variables
	var x = 0;
	var t = 0;
	var i = 0;
	var qDeep, qCoast;

	// Initialize counts of total ships in each area
	var opFleetDeep = 0;
	var opFleetCoast = 0;
	var opFleetHarbor = 0;


  //Build from old data
  var newGameState = JSON.parse(JSON.stringify(gameState));
  newGameState.year = gameState.year + 1;

	// Process teams individually
	for (t = 0; t<numberOfTeams; t++) {

    // Determine fleets and bank balances after trades and orders
    newGameState.teams[t].bankBalance = gameState.teams[t].bankBalance
      - teamDecisions[t].auctionDols
      - teamDecisions[t].shipPurchDols
      + teamDecisions[t].shipSalesDols;

    newGameState.teams[t].numberOfShips = gameState.teams[t].numberOfShips
                                          + teamDecisions[t].auctionShips
                                          + teamDecisions[t].shipPurch
                                          - teamDecisions[t].shipSales;

		// Determine total ships in fishing areas
		opFleetDeep += teamDecisions[t].shipsToDeep;
		opFleetCoast += teamDecisions[t].shipsToCoast;
		opFleetHarbor += newGameState.teams[t].numberOfShips
                     - teamDecisions[t].shipsToDeep
                     - teamDecisions[t].shipsToCoast;
	}

	// Ship efficiency multiplier for deep sea
	x = gameState.fishDeepSea/gameState.maximumFishDeepSea;
	for (i = 1; i <= 11; i++) {
		z[2*i] = productivity_1[i];
	}
	qDeep = table(x, z);

	// Ship efficiency multiplier for coastal areas
	x = gameState.fishCoast/gameState.maximumFishCoast;
	for (i = 1; i <= 11; i++) {
		z[2*i] = productivity_2[i];
	}
	qCoast = table(x, z);

	// Total harvest
	// Note: The modulo 20 operations in the indices allow the sequences to repeat
	//       if more than 20 turns are played
	var totalCatchDeep = opFleetDeep * qDeep * weather[gameState.year % 20];
	var totalCatchCoast = opFleetCoast * qCoast * weather[gameState.year % 20];

	// Adjust if harvest exceeds fish population
	if (totalCatchDeep > gameState.fishDeepSea) {
		qDeep *= gameState.fishDeepSea/totalCatchDeep;
	}
	if (totalCatchCoast > gameState.fishCoast) {
		qCoast *= gameState.fishCoast/totalCatchCoast;
	}

  var realTotalCatchDeep = 0;
  var realTotalCatchCoast = 0;
  var totalShipOrders = 0;

	// Process teams individually
	for (t = 0; t<numberOfTeams; t++) {

		// Catch
		var catchDeep = Math.floor(teamDecisions[t].shipsToDeep * qDeep * weather[gameState.year % 20] + 0.5);
		var catchCoast = Math.floor(teamDecisions[t].shipsToCoast * qCoast * weather[gameState.year % 20] + 0.5);
    realTotalCatchDeep += catchDeep;
    realTotalCatchCoast += catchCoast;

		// Income from harvest sales
		var fishSales = (catchDeep + catchCoast) * gameState.fishSalesPrice;

		// Operating expenses and order costs
		const expenseDeep = gameState.operatingCostDeepSea * teamDecisions[t].shipsToDeep;
		const expenseCoast = gameState.operatingCostCoast * teamDecisions[t].shipsToCoast;
		const expenseHarbor = gameState.operatingCostHarbor * (
                          newGameState.teams[t].numberOfShips
                          - teamDecisions[t].shipsToDeep
                          - teamDecisions[t].shipsToCoast
                        );
		const orderMoney = gameState.newShipPrice * teamDecisions[t].shipOrders;

    totalShipOrders += teamDecisions[t].shipOrders;

		// Bank balance
		const minBankBal = newGameState.teams[t].bankBalance - expenseDeep - expenseCoast - expenseHarbor;
    var interestEarned = 0;
    if (minBankBal < 0) {
			interestEarned = Math.floor((0.15 * minBankBal + 5)/10) * 10;
		} else {
			interestEarned = Math.floor((0.1 * minBankBal + 5)/10) * 10;
		}

		newGameState.teams[t].bankBalance = minBankBal + fishSales + interestEarned - orderMoney;

    // For first turn we override all bankBalance changes
    if(firstTurn) {
      newGameState.teams[t].bankBalance = gameState.teams[t].bankBalance;
    }

    newGameState.teams[t].teamReportData = {
      deepSeaCatch: catchDeep,
      coastCatch: catchCoast,
      totalFishSales: fishSales,
      interest: interestEarned,
    };

		// Ship fleet, one year construction delay on shiporders
    newGameState.teams[t].numberOfShips = newGameState.teams[t].numberOfShips
                                          + teamDecisions[t].shipOrders;

	}

  if(!firstTurn) {
    newGameState.fishDeepSea = Math.max(0, gameState.fishDeepSea - realTotalCatchDeep);
    newGameState.fishCoast = Math.max(0, gameState.fishCoast - realTotalCatchCoast);
  }

	// New fish
	x = newGameState.fishDeepSea/gameState.maximumFishDeepSea;
	for (i = 1; i <= 11; i++) {
		z[2*i] = regeneration[i];
	}
	const regenerationDeep = Math.floor(50 * table(x, z));

  x = newGameState.fishCoast/gameState.maximumFishCoast;
	for (i = 1; i <= 11; i++) {
		z[2*i] = regeneration[i];
	}
	const regenerationCoast = Math.floor(30 * table(x, z));

	// Fish populations
  if(!firstTurn) {
    newGameState.fishDeepSea += regenerationDeep;
    newGameState.fishCoast += regenerationCoast;
  }

	// Fish density
	const fishDensityDeepSea = Math.round(newGameState.fishDeepSea/gameState.maximumFishDeepSea*100)/100;
	const fishDensityCoast = Math.round(newGameState.fishCoast/gameState.maximumFishCoast*100)/100;

  // Indices
  const shipIndex = (Math.floor(20 * (opFleetDeep + opFleetCoast + opFleetHarbor)/(numberOfTeams * initialNumberOfShipsFromNumberOfTeams(numberOfTeams))))/10;
  const catchIndex = (Math.floor(20 * (realTotalCatchDeep + realTotalCatchCoast)/600))/10;
  const fishIndex = (Math.floor(100 * (newGameState.fishDeepSea + newGameState.fishCoast)/(newGameState.maximumFishDeepSea + newGameState.maximumFishCoast)))/10;


  const operatorReportData = {
    weather: weather[gameState.year % 20],
    operatingFleetDeepSea: opFleetDeep,
    operatingFleetCoast: opFleetCoast,
    operatingFleetHarbor: opFleetHarbor,
    operatingFleetTotal: opFleetDeep + opFleetCoast + opFleetHarbor,
    totalShipOrders,
    catchPerShipDeepSea: opFleetDeep === 0 ? 0 : realTotalCatchDeep/opFleetDeep,
    catchPerShipCoast: opFleetCoast === 0 ? 0 : realTotalCatchCoast/opFleetCoast,
    totalCatchDeepSea: realTotalCatchDeep,
    totalCatchCoast: realTotalCatchCoast,
    newFishDeepSea: regenerationDeep,
    newFishCoast: regenerationCoast,
    fishDensityDeepSea,
    fishDensityCoast,
    shipIndex,
    catchIndex,
    fishIndex,
  };

  newGameState.operatorReportData = operatorReportData;

  newGameState.shipSalvageValue = newShipSalvageValue(
    newGameState.year,
    gameState.shipSalvageValue,
    operatorReportData.operatingFleetHarbor,
    operatorReportData.operatingFleetCoast,
    operatorReportData.operatingFleetDeepSea,
    newGameState.operatingCostHarbor,
    newGameState.operatingCostCoast,
    newGameState.operatingCostDeepSea,
    operatorReportData.totalCatchCoast,
    operatorReportData.totalCatchDeepSea,
    newGameState.fishSalesPrice
  );

  return newGameState;
}

function fleetProfit(operatingFleet, totalCatch, operatingCost, fishSalesPrice) {
  if (operatingFleet === 0) {
    return 0;
  }
  return (fishSalesPrice * (totalCatch/operatingFleet) - operatingCost) * operatingFleet;
}

const SALVAGE_VALUE_DELAY = 2;
// Calculate the salvage value for a given year.
// Use variable salvage value process.
function newShipSalvageValue(yr, currentShipSalvageValue, opFleetHarbor, opFleetCoast, opFleetDeep, opCostHarbor, opCostCoast, opCostDeep, totalCatchCoast, totalCatchDeep, fishSalesPrice) {

//console.log(yr, currentShipSalvageValue, opFleetHarbor, opFleetCoast, opFleetDeep, opCostHarbor, opCostCoast, opCostDeep, totalCatchCoast, totalCatchDeep, fishSalesPrice);

  if(yr === 1) {
    return INITIAL_SHIP_SALVAGE_VALUE;
  }

  const harborCost = opCostHarbor * opFleetHarbor;
  const deepSeaProfit = fleetProfit(opFleetDeep, totalCatchDeep, opCostDeep, fishSalesPrice);
  const coastalProfit = fleetProfit(opFleetCoast, totalCatchCoast, opCostCoast, fishSalesPrice);
	const totalProfit = deepSeaProfit + coastalProfit - harborCost;
	const totalShips = opFleetDeep + opFleetCoast + opFleetHarbor;
  const averageProfit = totalProfit/totalShips;

  //console.log(harborCost, deepSeaProfit, coastalProfit, totalProfit, totalShips, averageProfit)

	var newShipSalvageValue = Math.floor(currentShipSalvageValue + ((averageProfit - currentShipSalvageValue)/SALVAGE_VALUE_DELAY));

	if (newShipSalvageValue < 0) {
    newShipSalvageValue = 0;
  }

	return newShipSalvageValue;
}
