import { combineReducers } from 'redux';

import {executeTurn, initialTeamDecisions} from '../lib/calculate';
import {updateGameStateOnServer} from "../lib/updateGameStateOnServer";

const initialConditionsReducer = (initialConditions = null, action) => {
  if (action.type === 'INITIAL_CONDITIONS_SET') {
    return action.payload;
  }
  return initialConditions;
};

const gameStatesReducer = (gameStates = [], action) => {
  if (action.type === 'INITIAL_CONDITIONS_SET') {
    const initialGameState = action.payload;
    const initialDecisions = initialTeamDecisions(initialGameState);
    const newGameState = executeTurn(initialGameState, initialDecisions);
    newGameState.decisions = initialDecisions;
    updateGameStateOnServer(newGameState.gameId,[newGameState]);
    return [newGameState];
  }
  if (action.type === 'DECISIONS_SET') {
    // We calculate a new game state after decisions are made
    const decisions = action.payload;
    const currentGameState = gameStates[gameStates.length-1];
    const newGameState = executeTurn(currentGameState, decisions);
    newGameState.decisions = decisions;
    const newGameStatesArray = [...gameStates, newGameState];
    //updateGameStateOnServer(newGameStatesArray[0].gameId,newGameStatesArray);
    return newGameStatesArray;
  }
  if (action.type === 'ROLLBACK_ONE_YEAR') {
    if(gameStates.length > 1) {
      const newGameStatesArray = gameStates.filter(gameState => gameState !== gameStates[gameStates.length-1]);
      updateGameStateOnServer(newGameStatesArray[0].gameId,newGameStatesArray);
      return newGameStatesArray;
    }
  }
  if (action.type === 'SET_TEAM_NAMES') {
    // Not updating game state on server, due to possible resetting of decesionform (not tested though)
    return gameStates.map(gameState => {
      gameState.teams = gameState.teams.map( (team,index) => {
          team.name = action.payload[index];
          return team;
      });
      return gameState;
    });
  }
  if (action.type === 'NEW_GAME_STATE_FROM_SERVER') {
    return action.payload;
  }
  if (action.type === 'QUIT_GAME') {
    return [];
  }
  return gameStates;
};

// This reducer is for the host of an online game, when decisions are made the results are
// not broadcast directly, instead an action needs to be performed by the host to broadcast
// results. This allows host to discuss results before users see them, and also consider rolling back.
// Rolling back one year gives direct broadcast of game state.
const gameStateOnServerStatusReducer = (gameStateOnServer = 'UPDATED', action) => {
  if (action.type === 'STARTED_SERVER_GAMESTATE_UPDATE') {
    return 'SENDING';
  }
  if (action.type === 'FINISHED_SERVER_GAMESTATE_UPDATE') {
    return 'UPDATED';
  }
  if (action.type === 'ERROR_SERVER_GAMESTATE_UPDATE') {
    return 'ERROR';
  }
  if (action.type === 'DECISIONS_SET') {
    return 'NOT_UPDATED';
  }
  if (action.type === 'ROLLBACK_ONE_YEAR') {
    return 'UPDATED';
  }
  return gameStateOnServer;
}

// This reducer contains an array of decision data comping from remote clients
// Structure is [{teamId: 1, decisions: {d1: 12 .......}]
const remoteDecisionsReducer = (remoteDecisions = [], action) => {
  if (action.type === 'REMOTE_DECISIONS_RECEIVED') {
    return [...remoteDecisions, action.payload];
  }
  if (action.type === 'DECISIONS_SET') {
    return [];
  }
  return remoteDecisions;
}

const licenseKeyReducer = (licenseKey = null, action) => {
  if (action.type === 'SET_LICENSE_KEY') {
    return action.payload;
  }
  return licenseKey;
}

const offlineModeAvailableReducer = (offlineModeAvailable = false, action) => {
  if ( action.type === 'SERVICE_WORKER_SUCCESSFULLY_INSTALLED' ) {
    return true;
  }
  return offlineModeAvailable;
}

const offlineModeAvailableMessageStatusReducer = (offlineModeAvailableMessageStatus = 'INVISIBLE', action) => {
  if (
    action.type === 'SERVICE_WORKER_SUCCESSFULLY_INSTALLED'
    && offlineModeAvailableMessageStatus !== 'DISMISSED'
  ) {
    return 'VISIBLE';
  }
  if ( action.type === 'DISMISS_OFFLINE_AVAILABLE_MESSAGE' ) {
    return 'DISMISSED';
  }
  return offlineModeAvailableMessageStatus;
}

const showRollbackMessageReducer = (showRollbackMessage = false, action) => {
  if ( action.type === 'ROLLBACK_ONE_YEAR' ) {
    return true;
  }
  if (action.type === 'HIDE_ROLLBACK_MESSAGE' || action.type === 'INITIAL_CONDITIONS_SET') {
    return false;
  }
  return showRollbackMessage;
}

const showCalculationModalReducer = (showCalculationModal = false, action) => {
  if (action.type === 'START_DECISIONS_SET') {
    return true;
  }
  if (action.type === 'DECISIONS_SET' || action.type === 'INITIAL_CONDITIONS_SET') {
    return false;
  }
  return showCalculationModal;
}

// This is data for joined teams, data can be changed when in SetupForm component
const joinedTeamsReducer = (joinedTeams = [], action) => {
  if (action.type === 'REMOTE_TEAM_JOINED') {
    console.log([...joinedTeams, action.payload]);
    return [...joinedTeams, action.payload];
  }
  // Reset joined teams when the initial conditions have been set
  if (action.type === 'INITIAL_CONDITIONS_SET') {
    return [];
  }
  if (action.type === 'QUIT_GAME') {
    return [];
  }
  return joinedTeams;
}

const onlineStateReducer = (onlineState = {}, action) => {
  if (action.type === 'JOINED_AS_TEAM') {
    return {teamId: action.payload.id, phase: 'WAITING_FOR_HOST'};
  }
  if (action.type === 'NEW_GAME_STATE_FROM_SERVER') {
    return {...onlineState, phase: 'PLAYING'}
  }
  if (action.type === 'STARTED_HOSTING') {
    return {gameId: action.payload.id, phase: 'STARTED_HOSTING'}// type: 'ONLINE_HOST', , phase: 'WAITING_FOR_PLAYERS'};
  }
  if (action.type === 'QUIT_GAME') {
    return {};
  }
  //if (action.type === 'SET_JOINED_TEAMS') {
  //  return {...onlineState, phase: 'CONFIGURING_INITIAL_CONDITIONS' };
  //}
  //if (action.type === 'STARTED_AS_OFFLINE_HOST') {
  //  return {type: 'OFFLINE_HOST'};
  //}
  return onlineState;
}

const latestReconnectCodeReducer = (latestReconnectCode = "", action) => {
  if ( action.type === 'JOINED_AS_TEAM' ) {
    return action.payload.reconnect_code;
  }
  if ( action.type === 'STARTED_HOSTING' ) {
    return action.payload.reconnect_host_code;
  }
  return latestReconnectCode;
}

export default combineReducers({
  initialConditions: initialConditionsReducer,
  remoteDecisions: remoteDecisionsReducer,
  gameStates: gameStatesReducer,
  gameStateOnServerStatus: gameStateOnServerStatusReducer,
  licenseKey: licenseKeyReducer,
  offlineModeAvailable: offlineModeAvailableReducer,
  offlineModeAvailableMessageStatus: offlineModeAvailableMessageStatusReducer,
  showRollbackMessage: showRollbackMessageReducer,
  showCalculationModal: showCalculationModalReducer,
  joinedTeams: joinedTeamsReducer,
  onlineState: onlineStateReducer,
  latestReconnectCode: latestReconnectCodeReducer,
});
