import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import { validateKey } from '../lib/licenseValidator';
import LicenseKeyForm from './LicenseKeyForm'
import JoinGameForm from './JoinGameForm'
import { PRIMARY_COLOR } from '../stylingConstants';
import ReconnectToGameForm from "./ReconnectToGameForm";

const SNOWFLAKE_EDUCATION_FISHBANKS_URL = 'https://www.snowflakeeducation.com/en/kopia-av-universitet/kopia-av-jordens-klimat-och-klimatforandringar/';

class Landing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLicenseForm: false,
    };
  }

  renderLinkToExisting() {
    // Disable for now until we have fixed so that only the one who created the game can restore it
    return null;
    if(this.props.hasExistingGame) {
      return (
        <Link to="show"><Button className="spaced" fluid>Go to saved game: {this.props.existingGameName}</Button></Link>
      );
    }
  }

  renderHostAGameContent() {
    if (this.props.hasLicense) {
      return (
        <>
          <p>The first option is to start a game where the teams connect and enter their own decisions (online).
            You  as a host can still modify their decisions if needed.
            <br/>
            The other option
            (local game) means you as a host will enter all the decisions. In this case the teams give decisions
            to you manually.</p>
          <Link to="new_online"><Button size='big' className="spaced" fluid color={PRIMARY_COLOR}>Start new online game</Button></Link>
          <Link to="new"><Button size='big' fluid color={PRIMARY_COLOR}>Start local game (run only by host)</Button></Link>
          {this.renderLinkToExisting()}
        </>
      );
    }
    if (this.state.showLicenseForm) {
      return <LicenseKeyForm/>
    }
    return (
      <>
        <p>To host a game you need a license key. This can be purchased from Snowflake education.</p>
        <a href={SNOWFLAKE_EDUCATION_FISHBANKS_URL} target="_blank">
          <Button color={PRIMARY_COLOR}>More information and purchase</Button>
        </a>
        <Button onClick={() => this.setState({showLicenseForm: true})}>I have a license key</Button>
      </>
    );
  }

  render() {
    return (
      <>
      <div>
        <h2>Welcome to Fishbanks</h2>
        <p>
          FishBanks is a computer-assisted, role-playing game that conveys
          a variety of lessons about sustainable use of renewable resources,
          team work, system dynamics, and decision making under conditions of
          uncertainty. It was created by Dennis Meadows and a number of his
          assistants, starting with a project at the International Institute
          for Applied Systems Analysis in Laxenburg.
        </p>
        <hr />
      </div>
      <div className="ui stackable two column grid">
        <div className="column">
          <JoinGameForm />
          <ReconnectToGameForm />
        </div>
        <div className="column">
          <h2>Host a game</h2>
          {this.renderHostAGameContent()}
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
    const hasExistingGame = (state.gameStates.length !== 0);
    let existingGameName = null;
    if(hasExistingGame) {
      existingGameName = state.gameStates[0].name;
    }
    return {
      hasExistingGame,
      existingGameName,
      hasLicense: validateKey(state.licenseKey),
    };
}


export default connect(mapStateToProps)(Landing);
