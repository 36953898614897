import {updateGameStateOnServer} from "../lib/updateGameStateOnServer";

export const rollbackOneYear = () => {
  return function(dispatch) {
    dispatch({
      type: 'ROLLBACK_ONE_YEAR'
    })
    setTimeout(function() {
      dispatch({
        type: 'HIDE_ROLLBACK_MESSAGE'
      })
    }, 4000);
  };
}

export const setInitialConditions = (initialConditions) => {
  return {
    type: 'INITIAL_CONDITIONS_SET',
    payload: initialConditions
  };
};

export const setDecisions = (decisions) => {
  return function(dispatch) {
    dispatch({
      type: 'START_DECISIONS_SET'
    })
    setTimeout(function() {
      dispatch({
        type: 'DECISIONS_SET',
        payload: decisions
      });
    }, 2000);
  };
};

export const setLicenseKey = (licenseKey) => {
  return {
    type: 'SET_LICENSE_KEY',
    payload: licenseKey
  };
};

export const dismissOfflineAvailableMessage = () => {
  return {
    type: 'DISMISS_OFFLINE_AVAILABLE_MESSAGE',
  }
}

export const setTeamNames = (teamNames) => {
  return {
    type: 'SET_TEAM_NAMES',
    payload: teamNames
  };
}

export const updateServerWithCurrentGameState = () => {
  return function(dispatch, getState) {
    dispatch({
      type: 'STARTED_SERVER_GAMESTATE_UPDATE'
    });
    const {gameStates} = getState();
    try {
      updateGameStateOnServer(gameStates[0].gameId, gameStates);
      dispatch({
        type: 'FINISHED_SERVER_GAMESTATE_UPDATE',
      });
    } catch(error) {
      console.log(error);
      dispatch({
        type: 'ERROR_SERVER_GAMESTATE_UPDATE',
      });
    }
  }
}

export const receivedFromHostChannel = (data) => {
  console.log('In action receivedFromHostChannel', data );
  if(data.team) {
    const team = JSON.parse(data.team);
    console.log('Team', team);
    return {
      type: 'REMOTE_TEAM_JOINED',
      payload: team,
    }
  }
  if(data.decisions) {
    console.log('Received decisions:', data.team_id, data.decisions);
    return {
      type: 'REMOTE_DECISIONS_RECEIVED',
      payload: {teamId: data.team_id, decisions: data.decisions}
    }
  }
}

export const receivedFromTeamChannel = (data) => {
  console.log('In action receivedFromTeamChannel', data );
  return {
    type: 'NEW_GAME_STATE_FROM_SERVER',
    payload: data,
  }
}

export const startedHosting = (game) => {
  return {
    type: 'STARTED_HOSTING',
    payload: game,
  }
}

export const quitCurrentGame = () => {
  return {
    type: 'QUIT_GAME',
  }
}

export const joinedAsTeam = (team) => {
  return {
    type: 'JOINED_AS_TEAM',
    payload: team,
  }
}