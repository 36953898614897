import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { Tab, Button, Icon, Popup, Message, Transition, Modal, Loader } from 'semantic-ui-react';

import { roundToDecimal, teamDisplayName } from '../lib/util';

import GameBoard from './GameBoard';
import TeamResultsPerYear from './TeamResultsPerYear';
import EnterDecisions from './EnterDecisions';
import TeamReports from './TeamReports';
import TeamReport from './TeamReport';
import Graphs from './Graphs';
import Notices from './Notices';
import { updateServerWithCurrentGameState } from '../actions';
import { PRIMARY_COLOR } from '../stylingConstants';


class ShowFishData extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      activeIndex: 0,
    }
  }

  showInitialTab = () => this.setState({ activeIndex: 0 });
  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });
  handleBroadcastResultsClicked = () => this.props.updateServerWithCurrentGameState();


  componentDidMount() {
    /*
      We use wakeLock to prevent the device from going into sleep mode.
      If device goes into sleep mode it will not receive messages sent on the
      channels. So hosts would not get decisions and clients will not get updated gamestate if we
      did not use this functionality.
     */
    if ('wakeLock' in navigator) {
      console.log("WakeLock is supported by this browser.");
      this.wakeLock = null;
      const requestWakeLock = () => {
        navigator.wakeLock.request('screen').then(
            (result) => {
              this.wakeLock = result;
              console.log("Acquired wakeLock.");
              this.wakeLock.onrelease = function(ev) {
                console.log("Released wakeLock.");
              };
            }
        ).catch(
            (err) => {
              console.log("Acquiring wakeLock failed, with the following error:", err);
            });
      }

      this.handleVisibilityChange = () => {
        if (this.wakeLock !== null && document.visibilityState === 'visible') {
          requestWakeLock();
        }
      }
      document.addEventListener('visibilitychange', this.handleVisibilityChange);

      requestWakeLock();
    }
  }

  componentWillUnmount() {
    if(this.wakeLock) {
      this.wakeLock.release();
    }
    if(this.handleVisibilityChange) {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange)
    }
  }

  componentDidUpdate(prevProps) {
    // Need to compare props to not cause infinite loop
    // If we have got a change of year we want to show the team report tab
    if (this.props.gameState && this.props.gameState.year !== prevProps.gameState.year) {
      console.log('Year updated in props, setting active tab index to zero.');
      this.setState({activeIndex: 0});
    }
  }

  renderOnlineHostInfoForYearOne() {
    return (
      <Message info>
        <Message.Header>
          You are the host of this game
        </Message.Header>
        <p>
          The teams cannot see all the information that you can see, so we recommend not sharing your screen.
          The teams can see the tabs 'Team results per year' (although not the exact number representing the weather)
          and 'Boat placement'. They can also see their own team report
          and enter their own decisions. When the teams have submitted their decisions they will appear in the form under
          'Enter decisions'. You can then alter them if necessary before pressing the 'Go fishing' button.
        </p>
      </Message>
    );
  }

  renderResultBroadcastMessage() {
    if (this.props.gameStateOnServerStatus === 'ERROR') {
      return <Message error>
        <p>There was an error broadcasting results to the players.</p>
        <Button
          onClick={this.handleBroadcastResultsClicked}
          disabled={this.props.gameStateOnServerStatus === 'STARTED_SERVER_GAMESTATE_UPDATE'}
          >
          Retry broadcasting results
        </Button>
      </Message>
    }
    return (
      <Message info>
        <Message.Header>
          Only you as host can see this years results until you broadcast them
        </Message.Header>
        <p>
          To broadcast results to the players, press the button. The players cannot send their decisions
          until you do this. If you see in
          the operator report that fish density is low, you might want to move back one year instead of
          broadcasting results to allow students to rethink their decisions.
        </p>
        <Button
          onClick={this.handleBroadcastResultsClicked}
          disabled={this.props.gameStateOnServerStatus === 'STARTED_SERVER_GAMESTATE_UPDATE'}
          color={PRIMARY_COLOR}
          size='big'
        >
          Broadcast results
        </Button>
      </Message>
    );
  }

  renderShipsToShipYardNotice(totalShipOrders) {
    if(totalShipOrders > 0) {
      if(this.props.onlineHost) {
        return (
          <p>{totalShipOrders} where ordered last year and have been placed in the shipyard.</p>
        )
      }
      return (
        <p>
          Place {totalShipOrders} ships in the shipyard.
          <Popup
            trigger={<Icon name='info' color='blue' />}
            content='The total amount of ships ordered last year should be placed in the shipyard.'
          />
        </p>
      );
    } else {
      return (
        <p>No ships were ordered last year.</p>
      );
    }
  }

  renderOperatorReport(gameState) {
    const {
      year,
      fishDeepSea,
      fishCoast,
      operatorReportData,
      shipSalvageValue,
      teams
    } = gameState;
    var totalNumberOfShips = 0;
    var totalBankBalance = 0;
    var totalAssets = 0;
    for (const team of teams) {
      totalNumberOfShips += team.numberOfShips;
      totalBankBalance += team.bankBalance;
      totalAssets += team.bankBalance + team.numberOfShips * shipSalvageValue;
    }

    return (
      <>
      {this.renderShipsToShipYardNotice(operatorReportData.totalShipOrders)}

      <h4>
        Indices for Worksheet
        <Popup
          trigger={<Icon name='info' color='blue' />}
          content='The indexes are normalized so they typically are between 0 and 10 throughout the game. So all three can easily be plotted on the same graph.'
        />
      </h4>
      <table className="ui collapsing definition table">
      <tbody>
      <tr><td className='right aligned'>
        Ship Index
        <Popup
          trigger={<Icon name='info' color='blue' />}
          content='2 x Ship Fleet (Excluding ships ordered last year) / Initial Ship Fleet'
        />
        </td><td>{operatorReportData.shipIndex}</td></tr>
      <tr><td className='right aligned'>
        Catch Index
        <Popup
          trigger={<Icon name='info' color='blue' />}
          content='Total Catch / 300'
        />
      </td><td>{operatorReportData.catchIndex}</td></tr>
      <tr>
        <td className='right aligned'>
        Fish Index
        <Popup
          trigger={<Icon name='info' color='blue' />}
          content='Total fish population / 450'
        />
        </td><td>{operatorReportData.fishIndex}</td></tr>
      </tbody>
      </table>

      <h4>Fish and ship data</h4>
      <table className="ui collapsing celled structured table">
      <tbody>
      <tr><td colSpan='2' className='right aligned'>Weather</td><td>{roundToDecimal(operatorReportData.weather,2)}</td></tr>
      <tr><td rowSpan='2'>Operating Fleet</td><td className='right aligned'>Deep Sea</td><td>{operatorReportData.operatingFleetDeepSea}</td></tr>
      <tr><td className='right aligned'>Coast</td><td>{operatorReportData.operatingFleetCoast}</td></tr>
      <tr><td rowSpan='2'>Catch per Ship</td><td className='right aligned'>Deep Sea</td><td>{roundToDecimal(operatorReportData.catchPerShipDeepSea, 1)}</td></tr>
      <tr><td className='right aligned'>Coast</td><td>{roundToDecimal(operatorReportData.catchPerShipCoast, 1)}</td></tr>
      <tr><td rowSpan='2'>Total Catch</td><td className='right aligned'>Deep Sea</td><td>{operatorReportData.totalCatchDeepSea}</td></tr>
      <tr><td className='right aligned'>Coast</td><td>{operatorReportData.totalCatchCoast}</td></tr>
      <tr><td rowSpan='2'>New Fish</td><td className='right aligned'>Deep Sea</td><td>{operatorReportData.newFishDeepSea}</td></tr>
      <tr><td className='right aligned'>Coast</td><td>{operatorReportData.newFishCoast}</td></tr>
      <tr><td rowSpan='2'>Fish Population</td><td className='right aligned'>Deep Sea</td><td>{fishDeepSea}</td></tr>
      <tr><td className='right aligned'>Coast</td><td>{fishCoast}</td></tr>
      <tr><td rowSpan='2'>Fish Density</td><td className='right aligned'>Deep Sea</td><td>{operatorReportData.fishDensityDeepSea}</td></tr>
      <tr><td className='right aligned'>Coast</td><td>{operatorReportData.fishDensityCoast}</td></tr>
      <tr><td colSpan='2' className='right aligned'>Salvage Value</td><td>{shipSalvageValue}</td></tr>
      <tr><td colSpan='2' className='right aligned'>Total Ship Orders</td><td>{operatorReportData.totalShipOrders}</td></tr>
      </tbody>
      </table>
      <h4>Team performance - Year {year}</h4>
      <table className="ui collapsing definition striped table">
      <thead>
      <tr>
        <th>Team</th><th>Ships</th><th>Bank Balance ($)</th>
        <th>Total Assets ($)
          <Popup
            trigger={<Icon name='info' color='blue' />}
            content='Total Assets = Bank balance + Number of Ships x Salvage Value'
          />
        </th>
      </tr>
      </thead>
      <tbody>
      {teams.map((team, index) => {
        return (
          <tr key={`team_row_${gameState.year}_${index}`}>
            <td>{teamDisplayName(team)}</td>
            <td>{team.numberOfShips}</td>
            <td>{team.bankBalance}</td>
            <td>{team.bankBalance + team.numberOfShips * shipSalvageValue}</td>
          </tr>
        )
      })}
      <tr className='summed-row'>
        <td>Total</td>
        <td>{totalNumberOfShips}</td>
        <td>{totalBankBalance}</td>
        <td>{totalAssets}</td>
      </tr>
      </tbody>
      </table>

      </>
    );
  }

  renderOperatorReportTabContent() {
    const historicOperatorReportPanes = this.props.gameStates.slice(0,-1).map(
      (gameState) => { return {
        menuItem: `Year ${gameState.year}`,
        pane: {
          key: `historic_${gameState.year}`,
          content: (<Tab.Pane>{this.renderOperatorReport(gameState)}</Tab.Pane>)
        }
      }}
    );


    return (
      <div>
        <Notices gameStates={this.props.gameStates} />
      <h3>Operator's report - {this.props.gameState.name}</h3>
      {this.renderOperatorReport(this.props.gameState)}
      <hr/>
      <h2>Historic operator reports</h2>
      <Tab
        panes={historicOperatorReportPanes}
        renderActiveOnly={false}
      />
      </div>
    );
  }

  renderRollbackMessage() {
    return (
      <Transition visible={this.props.showRollbackMessage} animation='fade down' duration={500}>
        <Message success>
          <p>
            Successfully rolled back from year {this.props.gameState.year + 1} to year {this.props.gameState.year}.
          </p>
        </Message>
      </Transition>
    );
  }

  renderCalculationModal() {
    return (
      <Modal
        open={this.props.showCalculationModal}
        basic
        size='small'
      >
        <Modal.Content>
          <h3><Loader inverted active>Simulating fishing...</Loader></h3>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    if (this.props.redirectToHome) {
      return (
        <Redirect to='/' />
      )
    }
    const panes = [
      { menuItem: 'Team results per year', pane: { key: 'tab1', content: (<Tab.Pane><TeamResultsPerYear gameStates={this.props.gameStates} showWeatherDetails={!this.props.singleTeamMode}/></Tab.Pane>)} },
      { menuItem: 'Boat placement', pane: { key: 'tab2', content: (<Tab.Pane><GameBoard gameState={this.props.gameState} /></Tab.Pane>)} },
    ];
    if(this.props.singleTeamMode) {
      panes.push({ menuItem: 'Team report', pane: {key: 'tab3', content: (
        <Tab.Pane>
          <>
            <h3>Team Report</h3>
            <hr />
            <TeamReport
              team={this.props.team}
              year={this.props.gameState.year}
              fishSalesPrice={this.props.gameState.fishSalesPrice}
            />
          </>
        </Tab.Pane>
          )}});
    } else {
      panes.push({ menuItem: 'Operator report', pane: { key: 'tab4', content: (<Tab.Pane>{this.renderOperatorReportTabContent()}</Tab.Pane>) } });
      panes.push({ menuItem: 'Team reports', pane: { key: 'tab5', content: (
        <Tab.Pane>
          <ReactToPrint
            trigger={() => <Button>Print the Team Reports</Button>}
            content={() => this.componentRef}
            pageStyle="@page: {margin: 2cm;}"
          />
          <TeamReports gameState={this.props.gameState} ref={el => (this.componentRef = el)} />
        </Tab.Pane>
      )} });
    }

    // Setting a key to the EnterDecisions to force a remount of EnterDecisions
    // when the year is changed. This is needed due to EnterDecisions using
    // state that is derived from props. (Probably an antipattern, so a redesign
    // could be good here)
      panes.push({
        menuItem: 'Enter decisions',
        pane: { key: 'tab6', content: (<Tab.Pane><EnterDecisions callbackAfterDecisions={this.showInitialTab.bind(this)} key={this.props.gameStates.length} /></Tab.Pane>)} });
    if(!this.props.singleTeamMode) {
      panes.push({ menuItem: 'Graphs', pane: { key: 'tab7', content: (<Tab.Pane><Graphs gameStates={this.props.gameStates}/></Tab.Pane>) } });
    }

    return (
      <>
        <h2>Year {this.props.gameState.year}</h2>
        {this.renderRollbackMessage()}
        {this.props.showResultBroadcastMessage && this.renderResultBroadcastMessage()}
        {this.renderCalculationModal()}
        {this.props.onlineHost && this.props.gameState.year === 1 && this.renderOnlineHostInfoForYearOne()}
        <Tab
          panes={panes}
          renderActiveOnly={false}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
        />
      </>
    );
  }

}


const mapStateToProps = (state) => {
  const gameStates = state.gameStates;
  // singleTeamMode is when you are the client of an online host
  const singleTeamMode = !!state.onlineState.teamId;
  console.log('SingleTeamMode: ', singleTeamMode);
  const onlineHost = !!state.onlineState.gameId;
  console.log('OnlineHost: ', onlineHost)
  const gameState = gameStates[gameStates.length-1];
  const showResultBroadcastMessage = state.gameStateOnServerStatus !== 'UPDATED' && onlineHost;
  console.log('GamestateOnServerStatus: ', state.gameStateOnServerStatus)
  let team = null;
  if( singleTeamMode ) {
    team = gameState.teams.find((team) => team.id === state.onlineState.teamId);
  }
  return {
    singleTeamMode,
    onlineHost,
    team,
    redirectToHome: gameStates.length === 0,
    gameState: gameStates[gameStates.length-1],
    gameStates,
    showRollbackMessage: state.showRollbackMessage,
    showCalculationModal: state.showCalculationModal,
    showResultBroadcastMessage,
    gameStateOnServerStatus: state.gameStateOnServerStatus,
  };
}

export default connect(mapStateToProps, {updateServerWithCurrentGameState})(ShowFishData);
